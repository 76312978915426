
import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { AuthorsAPI } from '@/api'
import { PageHeader } from '@/components/page-header'
import {
  Author, AuthorTotalPubs, PublishedAs, CoAuthors,
  Publications, Journals, Series, AuthorSerialsIds, Classifications,
  AuthorExtraInfoComponent, AuthorEdit, getLastNameInitial, AuthorExtraInfo,
  PublicationsBySerial, CollabDistanceCard,
} from '@/components/author-page'
import { Card } from '@/components/card'
import { Route } from 'vue-router'
import { trackAuthorCounter5 } from '@/counter5-tracker'
import { scrollTo } from '@/utils/utils'

@Component({
  components: {
    PageHeader,
    Card,
    AuthorTotalPubs,
    PublishedAs,
    CoAuthors,
    Publications,
    Journals,
    Series,
    Classifications,
    AuthorExtraInfoComponent,
    AuthorEdit,
    PublicationsBySerial,
    CollabDistanceCard,
  },
})
export default class AuthorPage extends Vue {
  @Ref('publications-component') publicationsComponent!: HTMLElement

  //
  // REACTIVE PROPERTIES
  //
  authorId = -1
  author: Author | null = null
  serialsIds: AuthorSerialsIds = {
    journalIds: [],
    seriesIds: [],
  }

  loading = true
  tabIndex = 0
  showShareModal = false
  dismissCountDown = 0
  // editMode = false

  //
  // WATCHERS
  //

  @Watch('$route', { immediate: true })
  onAuthorIdChanged(to: Route) {
    if (to.query.authorId !== undefined && this.authorId.toString() !== to.query.authorId as string) {
      this.updateAuthorId(+to.query.authorId)
    }

    // this.editMode = to.query.edit !== undefined && to.query.edit === 'true'
  }

  //
  // COMPUTED PROPERTIES
  //

  get permalinkUrl() {
    return 'https://mathscinet.ams.org/mathscinet/MRAuthorID/' + this.authorId
  }

  get getLastNameInitial() {
    return getLastNameInitial(this.author)
  }

  get journalIdsNonEmpty() {
    return this.serialsIds && this.serialsIds.journalIds.length > 0
  }

  get seriesIdsNonEmpty() {
    return this.serialsIds && this.serialsIds.seriesIds.length > 0
  }

  //
  // LIFE CYCLE HOOKS
  //

  mounted() {
    document.title = 'Author - MathSciNet'

    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  //
  // EVENT HANDLERS
  //
  onClickCancelEditAuthorProfile() {
    this.removeEditParamFromURL()
  }

  onClickSavedAuthorProfile(authorExtraInfo: AuthorExtraInfo) {
    this.removeEditParamFromURL()

    if (this.author) {
      this.author.extraInfo = authorExtraInfo
    }
  }

  //
  // METHODS
  //

  removeEditParamFromURL() {
    if (this.$route.query.edit === 'true') {
      this.$router.replace({
        name: 'AuthorPage',
        query: {
          authorId: this.authorId.toString(),
        },
      })
    }
  }

  updateAuthorId(authorId: number) {
    this.authorId = authorId
    this.fetchData()
  }

  checkURLParams() {
    const authorIdNumber = this.$route.query.authorId ? +this.$route.query.authorId : -1
    this.authorId = isNaN(authorIdNumber) ? -1 : authorIdNumber
  }

  async fetchData() {
    this.fetchAuthorSerialsIds()
    await this.fetchAuthor()
    trackAuthorCounter5(this.author)
  }

  private async fetchAuthor() {
    this.loading = true
    this.author = await AuthorsAPI.getAuthor(this.authorId)
    if (this.author !== null) {
      document.title = `${this.author.profileName} - MathSciNet`
    }
    this.loading = false
  }

  private async fetchAuthorSerialsIds() {
    this.serialsIds = await AuthorsAPI.getAuthorSerialsIds(this.authorId)
  }

  setTabIndexReviews() {
    this.tabIndex = 2
    this.$nextTick(() => {
      scrollTo(this.publicationsComponent, 'start')
    })
  }

  copyPermalink() {
    const copyText = document.getElementById('sharedUrlText') as HTMLInputElement
    if (copyText) {
      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */
      navigator.clipboard.writeText(copyText.value)
      copyText.setSelectionRange(0, 0) /* Clear the highlighting from the text */

      // Notify that the text was copied
      this.dismissCountDown = 3
    }
  }

  countDownChanged(dismissCountDown: number): void {
    this.dismissCountDown = dismissCountDown
  }
}
